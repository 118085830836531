import React from 'react';
import styled from 'styled-components';

// component
import { Navbar } from '../common';

import logoAnimation from '../../images/backgrounds/logo-animations.gif';

const HomePageHeader = () => {
  return (
    <>
      <div>
        <div className="vimeo-container">
          <iframe
            src="https://www.youtube.com/embed/jn5D0OU9pT8?&autoplay=1&loop=1&rel=0&showinfo=0&color=white&iv_load_policy=3&playlist=jn5D0OU9pT8&controls=0&mute=1"
            allowfullscreen="allowfullscreen"
            frameborder="0"
            allow="autoplay; fullscreen"
            class="ytplayer"
            id="ytplayer"
          ></iframe>

          <img src={logoAnimation} />

          <Navbar />

          <a href="#home-section-section" class="ca3-scroll-down-link ca3-scroll-down-arrow"></a>
        </div>
      </div>
    </>
  );
};

export default HomePageHeader;
