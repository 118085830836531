import React from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';
import { Navbar } from 'src/components/common';
import { Image } from '../common';

const AboutPageHeader = () => {
  return (
    <StyledAboutPageHeader>
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
        }}
      >
        <Navbar dark={true} />
      </div>
      <div className="left">
        <Typist
          cursor={{
            show: true,
            blink: true,
            element: '_',
            hideWhenDone: true,
            hideWhenDoneDelay: 100,
          }}
          className="header"
        >
          Telling the diverse Beautiful Stories of Africa is our favourite thing to do.{' '}
        </Typist>
      </div>
      <div className="right">
        <Image name="team-photo" width="100%" height="100%" />
      </div>
    </StyledAboutPageHeader>
  );
};

export const StyledAboutPageHeader = styled.header`
  * {
    color: black;
  }

  min-height: 80vh;
  background-color: #f8f8f8;
  display: flex;
  width: 100%;

  @media only screen and (max-width: 600px) {
    min-height: 50vh;
  }

  .left {
    flex-basis: 60%;
    padding: 0rem 15rem;
    margin: auto 0;

    @media only screen and (max-width: 600px) {
      flex-basis: 100%;
      padding: 2rem;
    }

    .header {
      font-size: 2.5rem;
      line-height: 50px;

      @media only screen and (max-width: 600px) {
        font-size: 2rem;
        text-align: center;
      }
    }

    .subtext {
      color: ${({ theme }) => theme.colors.darkBlueLighter3x};
      margin: 2rem 0;
      line-height: 27px;
    }
  }

  .right {
    flex-basis: 40%;

    @media only screen and (max-width: 600px) {
      display: none;
    }

    div {
      height: 100%;

      img {
        scale: 0.4 !important;
      }
    }
  }
`;

export default AboutPageHeader;
