import React from 'react';
import styled from 'styled-components';

// component
import { Navbar } from '../common';

const ContactPageHeader = () => {
  return (
    <StyledContactPageHeader>
      <Navbar />
      <h1>Contact tagline Here</h1>
    </StyledContactPageHeader>
  );
};

const StyledContactPageHeader = styled.section`
  min-height: 90vh;
  background-size: cover;
  background: url('https://res.cloudinary.com/beautiful-stories/image/upload/v1589881303/auto-fits/IMG_5634_hnopw0.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: auto;
    color: white;
    text-align: center;
    font-size: 5rem;
  }
`;

export default ContactPageHeader;
