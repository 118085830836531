import React, { useState } from 'react';
import { BackgroundSectionGeneric, Navbar } from '../common/';
import styled from 'styled-components';
import { HomePageImage } from '../Photography';
import { Link } from 'gatsby';

const sections = [
  {
    name: 'events',
    imageSize: '100%',
    path: '/events',
  },

  {
    name: 'commercials',
    imageSize: '100%',
  },
  {
    name: 'portraits',
    imageSize: '50%',
  },
  {
    name: 'documentary',
    imageSize: '100%',
  },
  {
    name: 'weddings',
    imageSize: '90%',
  },
  {
    name: 'stocks',
    imageSize: '100%',
  },
  {
    name: 'fashion & lifestyle',
    imageSize: '100%',
  },
];

const PortfolioHeader = () => {
  const [activeSection, setActiveSection] = useState(null);

  return (
    <BackgroundSectionGeneric name="BackgroundImage">
      <HeaderArea>
        <Navbar />
        <div className="inner">
          <div className="left">
            <div className="left-inner">
              {sections.map((item, index) => {
                return (
                  <Link to={`/photography/portfolio/${item.path}`} className="category-link">
                    <h2
                      key={index}
                      className="category"
                      onMouseEnter={() => setActiveSection(item)}
                    >
                      {item.name}
                    </h2>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="right">
            {activeSection && (
              <HomePageImage name={activeSection.name} width={activeSection.imageSize} />
            )}
          </div>
        </div>
      </HeaderArea>
    </BackgroundSectionGeneric>
  );
};

export default PortfolioHeader;

const HeaderArea = styled.div`
  * {
    color: black;
  }

  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex: 1;

  .inner {
    padding: 2rem 5rem;
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .left {
    height: auto;
    border: none;
    flex: 0.4;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .left-inner {
      display: flex;
      flex-direction: column;
    }

    .category-link {
      display: inline-block;
      margin-bottom: 1.45rem;

      .category {
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.darkBluePrimary};
        position: relative;
        z-index: 1;
        display: inline-flex;

        &::before {
          transition: width 0.1s ease-out;
          content: '';
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            to top,
            ${({ theme }) => theme.colors.goldPrimary} 25%,
            rgba(0, 0, 0, 0) 35%
          );

          position: absolute;
          left: 0;
          bottom: 2px;
          z-index: -1;

          will-change: width;
          transform: rotate(-2deg);
          transform-origin: left bottom;
        }

        ::before {
          transition: width 0.1s ease-out;
        }

        :hover {
          ::before {
            width: 0px;
            transition-duration: 0.15s;
          }
        }
      }
    }
  }

  .right {
    flex: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
